
<template>
	<div class="container">
		<div class="course-banner">
			<el-carousel :interval="5000" arrow="hover" indicator-position="none" :height="bannerHeight+'px'">
				<el-carousel-item :data="bannerList" v-for="item in bannerList" :key="item.id">
					<img :src="item.image" class="image" @click="openBanner(item.url)">
				</el-carousel-item>
			</el-carousel>
		</div>
		<div class="course-main">
			<div class="menuCon">				
				<div class="menu flex flex-center">						
					<div class="nav-items" :class="type == item.ip_type ? 'active' : ''" v-for="(item, index) in courseList"
						:key="'type' + index" @click="onSwitch(index)">
						{{ item.course_name }}
					</div>
						
				</div>				
			</div>
			<div class="textCon">
				<div class="main" v-html="mainCon"></div>
			</div>
			<div class="signupBtn">
				<img :src="imgbg" alt="" @click="goCourseEnroll('CourseEnroll')">
			</div>		
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				type: this.$route.query.id ? this.$route.query.id : 1,
				image:'',
				mainCon:'',
				imgbg:'',
				courseList:[],
				bannerList:[],
				bannerHeight:0,
				screenWidth:0
			};
		},
		mounted () {
			// 首次加载时,初始化高度
			this.screenWidth = window.innerWidth
			this.bannerHeight = 600 / 1550 * this.screenWidth
			// 窗口大小发生改变
			window.onresize = () => {
				this.screenWidth = window.innerWidth
				this.bannerHeight = 600 / 1550 * this.screenWidth
			}
		},
		created() {
			this.getCourseList();
			this.getBannerList();		
		},
		methods: {
			getCourseList(){
				this.$http
				.get('/api/web/index/getCourseList')
				.then(res=>{
					this.courseList=res.data.courseList;
					// console.log(this.courseList[this.type-1].content)
					this.mainCon = this.courseList[this.type-1].content;
					this.imgbg=this.courseList[this.type-1].image;
				})
			},
			onSwitch(index) {
				this.type = this.courseList[index].ip_type;
				this.mainCon = this.courseList[this.type-1].content;
				this.imgbg = this.courseList[index].image;		
			},
			goCourseEnroll(){
				this.$router.push({
					name:'CourseEnroll',
					query:{
						type:this.type	
					}
				},)
			},
			getBannerList(){
				this.$http.get('/api/web/index/getCourseBanner')
					.then((res) => {
						this.bannerList = res.data.bannerList
					})
			},
			//跳转,跳转之前检验url地址是否为空
			openBanner(url){
				if(!!url){
					// console.log(url)
					window.open(url, '_blank');	
				}	else{
					return false
				}			
			},
		}
	};
</script>

<style lang='less' scoped>
	.container{
		width: 100%;
		height: 100%;
		background: #fff;
		/deep/.course-banner{
			width: 100%;
			img{
				display: block;
				width: 100%;
				height: inherit;
			}
		}
	}
	.course-main{
		width: 100%;
		.menuCon{
			width: var(--container-width);
			margin: 0 auto;
			border-bottom: solid 2px #d71a18;
			.menu{
				// width: 800px;
				margin: 0 auto;
				.nav-items{
					padding: 0 30px;
					height: 80px;
					line-height: 80px;
					font-size: 22px;
					font-weight: 600;
					margin: 0 15px;
					cursor: pointer;
				}
				.nav-items.active{
					color: white;
					background-color: #d71a18;
				}
			}
		}
		/deep/.textCon{
			width: 1300px;
			padding: 30px 0;
			margin: 0 auto;
			.main{
				width: 100%;
				img{
					display: block;	
					max-width: 100%;			
					margin: 0 auto;
					
				}
			}			
		}
		.signupBtn{
			width: 1300px;
			height: auto;
			margin: 0 auto 100px;
			background-size: cover;
			text-align: center;
			
			img{
				display: block;
				max-width: 100%;
				margin: 0 auto;
				cursor: pointer;
			}
		}
		.btn{
			width: 150px;
			height: 60px;
			line-height: 60px;
			font-size: 16px;
			padding: 0;
			text-align: center;
			margin: 0 auto 100px;
		}
	}
	
</style>
